import { Input } from "@/components/ui/input";
import { companyApi } from "@/api/endpoints/companyApi";
import { blobUrl } from "@/api/reduxApi";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";

const CompanyImageUploadForm = () => {
  const [update] = companyApi.useUpdateCompanyLogoMutation();
  const { activeCompany } = useLoggedIn();

  const handleSubmit = (file: File | null) => {
    if (!file) return;

    update({ logo: file });
  };
  return (
    <>
      <h4 className={"text-xl"}>Firmenlogo</h4>
      <VerticalFlex align={"center"}>
        {activeCompany.logoBlob ? (
          <img
            key={activeCompany.logoBlobId}
            src={blobUrl(activeCompany.logoBlob)}
            className={
              "shadow-s duration-400 max-h-[200px] max-w-[200px] rounded-md bg-primary object-contain shadow-black transition-all hover:scale-110"
            }
            alt={"logo preview"}
          />
        ) : (
          <div className="h-20 w-20 rounded-full bg-primary">
            <div className="flex h-full items-center justify-center text-muted">
              Kein Logo
            </div>
          </div>
        )}
      </VerticalFlex>

      <form className="w-full p-10">
        <Input
          type="file"
          accept="image/jpeg, image/png"
          className={"bg-primary text-muted"}
          onChange={(e) => {
            handleSubmit(e.target.files?.[0] || null);
          }}
        />
      </form>
    </>
  );
};

export default CompanyImageUploadForm;
