import { UseFormReturn } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { t } from "i18next";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { CompanyUpdateData, TaxSystem } from "@/models/company";
import { cn } from "@/lib/utils";
import {
  CommercialRegisterHoverInfo,
  RegistrationCourtHoverInfo,
  TaxIdHoverInfo,
  TaxSystemHoverInfo,
  VatIdHoverInfo,
} from "@/feature/settings/UserSettings/form/info/hoverInfo";
import { HorizontalFlex } from "@/components/layout/Flex";

function AccountingFormFields(props: {
  form: UseFormReturn<CompanyUpdateData>;
}) {
  const form = props.form;

  return (
    <div className="grid grid-cols-2 gap-4">
      {/* taxId field */}
      <FormField
        control={form.control}
        name="accounting.taxId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <HorizontalFlex>
                {t("component.companyForm.accounting.taxId")}
                <FormMessage />
                <TaxIdHoverInfo />
              </HorizontalFlex>
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      {/* Other fields like vatId, registrationCourt, etc. */}
      <FormField
        control={form.control}
        name="accounting.vatId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {" "}
              <HorizontalFlex>
                {t("component.companyForm.accounting.vatId")}
                <FormMessage />

                <VatIdHoverInfo />
              </HorizontalFlex>
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="accounting.registrationCourt"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <HorizontalFlex>
                {t("component.companyForm.accounting.registrationCourt")}
                <FormMessage />
                <RegistrationCourtHoverInfo />
              </HorizontalFlex>
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="accounting.commercialRegister"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <HorizontalFlex>
                {t("component.companyForm.accounting.commercialRegister")}
                <FormMessage />
                <CommercialRegisterHoverInfo />
              </HorizontalFlex>
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/*<FormField*/}
      {/*  control={form.control}*/}
      {/*  name="accounting.isSmallBusiness"*/}
      {/*  render={({ field }) => (*/}
      {/*    <FormItem className="col-span-2 rounded-lg border p-4 shadow-sm">*/}
      {/*      <div className="mt-2 grid grid-cols-3 items-center justify-between">*/}
      {/*        <span*/}
      {/*          className={cn(*/}
      {/*            !field.value ? "text-primary" : "text-muted-foreground",*/}
      {/*            "cursor-pointer",*/}
      {/*          )}*/}
      {/*          onClick={() => field.onChange(!field.value)}*/}
      {/*        >*/}
      {/*          {t("Standard")}*/}
      {/*          <br />*/}
      {/*          <span className={"text-muted-foreground"}> 19%</span>*/}
      {/*        </span>*/}
      {/*        <Switch*/}
      {/*          checked={field.value}*/}
      {/*          onCheckedChange={field.onChange}*/}
      {/*          className={"mx-auto"}*/}
      {/*        />*/}
      {/*        <span*/}
      {/*          className={cn(*/}
      {/*            field.value ? "text-primary" : "text-muted-foreground",*/}
      {/*            "cursor-pointer",*/}
      {/*          )}*/}
      {/*          onClick={() => field.onChange(!field.value)}*/}
      {/*        >*/}
      {/*          <span className={"flex gap-1"}>*/}
      {/*            {t("Kleinunternehmer")}*/}
      {/*            <SmallBusinessHoverInfo />*/}
      {/*          </span>*/}
      {/*          <span className={"text-xs text-muted-foreground"}>*/}
      {/*            {" "}*/}
      {/*            Umsatzsteuerbefreit{" "}*/}
      {/*          </span>*/}
      {/*        </span>*/}
      {/*        <FormMessage />*/}
      {/*      </div>*/}
      {/*    </FormItem>*/}
      {/*  )}*/}
      {/*/>*/}
      {/* Show VAT Rate Radio Group only if Standard tax system is selected */}
      {/*{!form.getValues("accounting.isSmallBusiness") && (*/}
      {/*  <FormField*/}
      {/*    control={form.control}*/}
      {/*    name="accounting.vatRate"*/}
      {/*    render={({ field }) => (*/}
      {/*      <FormItem>*/}
      {/*        <FormLabel>*/}
      {/*          <HorizontalFlex>*/}
      {/*            {t("component.companyForm.accounting.vatRate")}*/}

      {/*            <FormMessage />*/}
      {/*            <VatRateHoverInfo />*/}
      {/*          </HorizontalFlex>*/}
      {/*        </FormLabel>*/}
      {/*        <FormControl>*/}
      {/*          <RadioGroup*/}
      {/*            value={field.value?.toString()}*/}
      {/*            onValueChange={(value) => field.onChange(Number(value))}*/}
      {/*          >*/}
      {/*            <div className="flex items-center space-x-2">*/}
      {/*              <RadioGroupItem*/}
      {/*                value={VATRate.enum.Zero.toString()}*/}
      {/*                id="r0"*/}
      {/*              />*/}
      {/*              <FormLabel htmlFor="r0">0%</FormLabel>*/}
      {/*            </div>*/}
      {/*            <div className="flex items-center space-x-2">*/}
      {/*              <RadioGroupItem*/}
      {/*                value={VATRate.enum.Seven.toString()}*/}
      {/*                id="r7"*/}
      {/*              />*/}
      {/*              <FormLabel htmlFor="r7">7%</FormLabel>*/}
      {/*            </div>*/}
      {/*            <div className="flex items-center space-x-2">*/}
      {/*              <RadioGroupItem*/}
      {/*                value={VATRate.enum.Nineteen.toString()}*/}
      {/*                id="r19"*/}
      {/*              />*/}
      {/*              <FormLabel htmlFor="r19">19%</FormLabel>*/}
      {/*            </div>*/}
      {/*          </RadioGroup>*/}
      {/*        </FormControl>*/}
      {/*      </FormItem>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}
      <FormField
        control={form.control}
        name="accounting.taxSystem"
        render={({ field }) => {
          return (
            <FormItem className="col-span-2 rounded-lg border p-4 shadow-sm">
              <div className="mt-2 grid grid-cols-3 items-center justify-between">
                <span
                  className={cn(
                    field.value === "SKR03"
                      ? "text-primary"
                      : "text-muted-foreground",
                    "cursor-pointer",
                  )}
                  onClick={() => field.onChange("SKR03")}
                >
                  {TaxSystem.Enum.SKR03.toString()}
                  <br />
                  <span className={"text-xs text-muted-foreground"}>
                    Für handels- und dienstleistungsorientierte Unternehmen
                  </span>
                </span>
                <Switch
                  className={"mx-auto"}
                  checked={field.value === "SKR04"}
                  onCheckedChange={(checked) =>
                    field.onChange(checked ? "SKR04" : "SKR03")
                  }
                />
                <span
                  className={cn(
                    field.value === "SKR04"
                      ? "text-primary"
                      : "text-muted-foreground",
                    "cursor-pointer",
                  )}
                  onClick={() => field.onChange("SKR04")}
                >
                  <span className={"flex gap-1"}>
                    {TaxSystem.Enum.SKR04.toString()}
                    <TaxSystemHoverInfo />
                  </span>
                  <span className={"text-xs text-muted-foreground"}>
                    Für produktionsorientierte Unternehmen
                  </span>
                </span>
              </div>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      {/* VAT Liability Switch */}
      {/*<FormField*/}
      {/*  control={form.control}*/}
      {/*  name="accounting.vatLiability"*/}
      {/*  render={({ field }) => (*/}
      {/*    <FormItem className="mt-4">*/}
      {/*      <div className="flex items-center gap-4">*/}
      {/*        <FormLabel*/}
      {/*          className={*/}
      {/*            field.value ? "text-primary" : "text-muted-foreground/80"*/}
      {/*          }*/}
      {/*        >*/}
      {/*          <HorizontalFlex>*/}
      {/*            {t("component.companyForm.accounting.vatLiability")}*/}
      {/*            <FormMessage />*/}
      {/*            <VatLiabilityHoverInfo />*/}
      {/*          </HorizontalFlex>*/}
      {/*        </FormLabel>*/}
      {/*        <FormControl>*/}
      {/*          <Switch*/}
      {/*            checked={field.value}*/}
      {/*            onCheckedChange={field.onChange}*/}
      {/*          />*/}
      {/*        </FormControl>*/}
      {/*      </div>*/}
      {/*    </FormItem>*/}
      {/*  )}*/}
      {/*/>*/}
    </div>
  );
}

export default AccountingFormFields;
