import React, { FC, Fragment } from "react";
import { BiDocument, DocumentPosition } from "@/models/document";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { formatMoney } from "@/util/format";
import { Separator } from "@/components/ui/separator";
import { useGetAlLCategoriesQuery } from "@/api/endpoints/documentApi";
import { Spinner } from "@react-pdf-viewer/core";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useTranslation } from "react-i18next";

export const DocumentPositions: FC<{ document: BiDocument }> = ({
  document,
}) => {
  const { t } = useTranslation();

  let positions = document.invoicePositions || [];
  let categories = useGetAlLCategoriesQuery(document.id);

  if (positions.length == 0) {
    return <div></div>;
  }

  return (
    <Table className={"w-full px-0 pb-0 pt-0"}>
      <TableHeader>
        <TableRow className={"m-4"}>
          <TableHead className={"py-4"}>
            {t("model.document.category")}
          </TableHead>
          <TableHead className={"py-4 text-right"}>
            {t("common.netAmount")}
          </TableHead>
          <TableHead className={"py-4 text-right"} colSpan={2}>
            {t("common.taxAmount")}
          </TableHead>
          <TableHead className={"py-4 text-right"}>
            {t("common.grossAmount")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {positions.map((pos, index) => (
          <TableRow key={index}>
            <TableCell className={"py-4 text-foreground"}>
              {categories ? (
                categories?.data?.find((c) => c.key == pos.category)?.name ||
                pos.category
              ) : (
                <Spinner />
              )}
            </TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(calculateNet(pos))}
            </TableCell>
            <TableCell className={"py-4 text-right text-sm"}>
              {pos.vatPercentage}%
            </TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(calculateTax(pos))}
            </TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(pos.total)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {positions.length > 1 && (
        <TableFooter
          className={
            "border-t border-muted-foreground/50 bg-foreground/5 font-bold"
          }
        >
          <TableRow>
            <TableCell className={"py-4"}>
              <span>{t("common.total")}</span>
            </TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(sum(positions.map(calculateNet)))}
            </TableCell>
            <TableCell></TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(sum(positions.map(calculateTax)))}
            </TableCell>
            <TableCell className={"py-4 text-right"}>
              {formatMoney(sum(positions.map((p) => p.total)))}
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

function calculateNet(pos: DocumentPosition): number {
  let tax = calculateTax(pos);
  return pos.total - tax;
}

function calculateTax(pos: DocumentPosition): number {
  return Math.round(
    pos.total - pos.total / (1 + parseFloat(pos.vatPercentage) / 100),
  );
}

function sum(value: number[]): number {
  return value.reduce((a, b) => a + b, 0);
}
