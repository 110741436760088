import { UseFormReturn } from "react-hook-form";
import { CompanyUpdateData, legalForms } from "@/models/company";
import { t } from "i18next";
import { GenericComboBox } from "@/components/form/GenericComboBox";

export function LegalFormComboBox({
  form,
}: {
  form: UseFormReturn<CompanyUpdateData>;
}) {
  return (
    <GenericComboBox
      form={form}
      name="legalForm"
      label={t("component.companyForm.generalInfo.legalForm")}
      options={legalForms}
      placeholder={t("component.companyForm.generalInfo.legalFormPlaceholder")}
    />
  );
}
