import React from "react";

import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { t } from "i18next";
import { FileQuestion } from "lucide-react";
import { ALIGN_OPTIONS } from "@radix-ui/react-popper";

export const TaxIdHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.taxId")}
      align={"start"}
      description={t("component.companyForm.accounting.taxIdDesc")}
    />
  );
};

export const VatIdHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatId")}
      align={"end"}
      description={t("component.companyForm.accounting.vatIdDesc")}
    />
  );
};

// Registergericht Hover Info
export const RegistrationCourtHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.registrationCourt")}
      align={"start"}
      description={t("component.companyForm.accounting.registrationCourtDesc")}
    />
  );
};

// Handelsregister Hover Info
export const CommercialRegisterHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.commercialRegister")}
      align={"end"}
      description={t("component.companyForm.accounting.commercialRegisterDesc")}
    />
  );
};

// Umsatzsteuersatz Hover Info
export const VatRateHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatRate")}
      align={"start"}
      description={t("component.companyForm.accounting.vatRateDesc")}
    />
  );
};

// Steuersystem Hover Info
export const TaxSystemHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.taxSystem")}
      align={"end"}
      description={t("component.companyForm.accounting.taxSystemDesc")}
    />
  );
};

// Kleinunternehmerregelung Hover Info
export const SmallBusinessHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.isSmallBusiness")}
      align={"end"}
      description={t("component.companyForm.accounting.isSmallBusinessDesc")}
    />
  );
};

// Umsatzsteuerpflicht Hover Info
export const VatLiabilityHoverInfo = () => {
  return (
    <HoverInfo
      title={t("component.companyForm.accounting.vatLiability")}
      align={"start"}
      description={t("component.companyForm.accounting.vatLiabilityDesc")}
    />
  );
};
const HoverInfo = ({
  title,
  description,
  align,
}: {
  title: string;
  description: string;
  align: (typeof ALIGN_OPTIONS)[number];
}) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <QuestionMarkCircledIcon className={"text-muted-foreground"} />
      </HoverCardTrigger>
      <HoverCardContent align={align} side={"top"} className={"w-96"}>
        <div className="flex justify-between space-x-4">
          <Avatar className={"my-auto"}>
            <AvatarFallback>
              <FileQuestion className={"text-muted-foreground"} />
            </AvatarFallback>
          </Avatar>
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">{title}</h4>
            <p className="text-xs text-muted-foreground">{description}</p>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
