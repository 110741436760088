import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import React, { useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { FileQuestion, UserCheck } from "lucide-react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { CompanyUpdateData, CompanyUpdateSchema } from "@/models/company";
import GeneralInfoFormField from "@/feature/settings/UserSettings/form/optional/GeneralInfoFormField";
import CompanyInfoFormFields from "@/feature/settings/UserSettings/form/optional/CompanyInfoFormFields";
import AccountingFormFields from "@/feature/settings/UserSettings/form/optional/AccountingFormFields";
import AddressFormFields from "@/feature/settings/UserSettings/form/optional/AddressFormFields";
import BankDetailsFormFields from "@/feature/settings/UserSettings/form/optional/BankDetailsFormFields";
import { companyApi } from "@/api/endpoints/companyApi";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";

export const EditCompanyForm = () => {
  const { t } = useTranslation();
  const [update, result] = companyApi.useUpdateCompanyMutation();
  const { activeCompany } = useLoggedIn();

  const companyToUpdate = {
    ...activeCompany,
    accounting: {
      ...activeCompany.accounting,
      taxSystem: activeCompany?.accounting?.taxSystem || "SKR03",
    },
  } as CompanyUpdateData;

  const form = useForm<CompanyUpdateData>({
    resolver: zodResolver(CompanyUpdateSchema),
    defaultValues: companyToUpdate,
  });

  const [showLoading, setShowLoading] = React.useState(false);
  useEffect(() => {
    if (result.isLoading) {
      setShowLoading(true);
    } else {
      let timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [result.isLoading]);

  const { toast } = useToast();
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          form.handleSubmit(
            async (data) => {
              update(data).then((result) => {
                if ("error" in result) {
                  console.error("company_err", result.error);
                } else {
                  toast({
                    icon: UserCheck,
                    title: t("common.success"),
                    description: t("component.companyForm.success"),
                  });
                }
              });
            },
            (errors) => {
              console.error("company_err", errors);
            },
          )(e);
        }}
        onError={(errors) => console.log(errors)}
        className={"flex flex-col gap-4"}
      >
        <HorizontalFlex>
          <VerticalFlex className={"flex-1"}>
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-3">
              <GeneralInfoFormField form={form} className={"col-span-2"} />

              <HorizontalFlex
                align={"center"}
                className={"col-span-2 flex-row-reverse xl:col-span-1"}
              >
                <Avatar className={"my-auto"}>
                  <AvatarFallback>
                    <FileQuestion className={"text-muted-foreground"} />
                  </AvatarFallback>
                </Avatar>
                <p className="text-right text-xs text-muted-foreground">
                  {t(
                    "Die hier angegebenen Daten werden zur Rechnungserstellung verwendet.",
                  )}
                </p>
              </HorizontalFlex>
            </div>
            <Tabs className={"mt-4"} defaultValue={"companyContactInfo"}>
              <TabsList>
                <TabsTrigger value={"companyContactInfo"}>
                  {t("component.companyForm.companyContactInfo.title")}
                </TabsTrigger>
                <TabsTrigger value={"accounting"}>
                  {t("component.companyForm.accounting.title")}
                </TabsTrigger>
              </TabsList>

              <TabsContent value={"companyContactInfo"} className={"mt-2"}>
                <VerticalFlex
                  gap={8}
                  className={"rounded-lg border bg-card p-4"}
                >
                  <CompanyInfoFormFields form={form} />
                  <AddressFormFields form={form} />
                </VerticalFlex>
              </TabsContent>
              <TabsContent value={"accounting"} className={"mt-2"}>
                <VerticalFlex
                  gap={8}
                  className={"rounded-lg border bg-card p-4"}
                >
                  <BankDetailsFormFields form={form} />
                  <AccountingFormFields form={form} />
                </VerticalFlex>
              </TabsContent>
            </Tabs>

            <Button type="submit" disabled={showLoading} className={"max-w-48"}>
              {showLoading && (
                <ReloadIcon className="ml-2 h-4 w-4 animate-spin" />
              )}
              {t("component.contacts.submit")}
            </Button>
          </VerticalFlex>
        </HorizontalFlex>
      </form>
    </Form>
  );
};
