import React from "react";
import {
  BiDocument,
  BiDocumentKind,
  UploadDocumentStatus,
} from "@/models/document";
import { useTranslation } from "react-i18next";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { formatDate, formatPeriod, formatSimpleDate } from "@/util/format";
import DocumentField from "@/feature/documents/components/Document/DocumentField";
import KindBadge from "@/feature/documents/components/Document/KindBadge";
import { cn } from "@/lib/utils";
import SmallTransactionPreview from "@/feature/documents/components/Card/CardBody/SmallTransactionPreview";
import { ContactCard } from "@/feature/documents/components/Card/CardBody/ContactCard";
import { DocumentPositions } from "@/feature/documents/components/Card/CardBody/DocumentPositions";

export const DocumentCardBody = ({
  biDocument,
}: {
  biDocument: BiDocument;
}) => {
  const { t } = useTranslation();
  const processing =
    biDocument.uploadDocument?.ocrStatus === UploadDocumentStatus.Processing ||
    biDocument.uploadDocument?.ocrStatus === UploadDocumentStatus.Pending;

  return (
    <VerticalFlex gap={4}>
      <div className={cn("text-xs text-muted-foreground")}>
        {biDocument.uploadDocument && t("model.document.uploadDocument")}
        <span>{biDocument.createdAt && formatDate(biDocument.createdAt)}</span>
      </div>

      <HorizontalFlex gap={4} className={"rounded-md bg-muted/20"}>
        <KindBadge
          kind={biDocument.kind}
          processing={processing}
          sideways={true}
          small={true}
          className={"rounded-l-md"}
        />
        <VerticalFlex gap={0} className={"flex-1"}>
          <HorizontalFlex className={"flex-1"}>
            <h4 className="text-l font-semibold">
              {biDocument.invoiceNumber
                ? biDocument.invoiceNumber
                : t("model.document.invoiceNumber")}
            </h4>
            <FlexOne />
            <DocumentField
              label={t("model.document.invoiceDate")}
              className={"mr-2 mt-2 text-xs text-muted-foreground"}
              value={
                biDocument.invoiceDate
                  ? formatSimpleDate(biDocument.invoiceDate)
                  : "-"
              }
              processing={processing}
            />
          </HorizontalFlex>
          <DocumentField
            className={"mt-auto text-xs"}
            label={
              biDocument.performancePeriodTo
                ? t("model.document.performancePeriod")
                : t("model.document.performanceDate")
            }
            value={
              biDocument.performancePeriodFrom && biDocument.performancePeriodTo
                ? formatPeriod(
                    biDocument.performancePeriodFrom,
                    biDocument.performancePeriodTo,
                  )
                : formatSimpleDate(
                    biDocument.performancePeriodFrom || biDocument.invoiceDate,
                  )
            }
            processing={processing}
          />
          <FlexOne />
        </VerticalFlex>
      </HorizontalFlex>
      {biDocument.kind == BiDocumentKind.Invoice && (
        <ContactCard contact={biDocument.recipient} kind={biDocument.kind} />
      )}
      {biDocument.kind == BiDocumentKind.Receipt && (
        <ContactCard contact={biDocument.issuer} kind={biDocument.kind} />
      )}

      {biDocument.bookingTransactions &&
        biDocument.bookingTransactions.length > 0 && (
          <VerticalFlex gap={0} className="mt-4">
            <>
              <span className="font-light">
                {t("model.document.transaction")}
              </span>
              {biDocument.bookingTransactions &&
                biDocument.bookingTransactions.map(
                  (transaction) =>
                    transaction.argument.match?.transactionIds !==
                      undefined && (
                      <SmallTransactionPreview
                        transactionIds={
                          transaction.argument.match.transactionIds
                        }
                      />
                    ),
                )}
            </>
          </VerticalFlex>
        )}

      <DocumentPositions document={biDocument} />
    </VerticalFlex>
  );
};
