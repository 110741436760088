import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { t } from "i18next";
import { CompanyUpdateData } from "@/models/company";
import CountrySelect from "@/feature/settings/UserSettings/form/optional/addr/CountrySelect";
import { FlexOne } from "@/components/layout/Flex";

const AddressFormFields = ({
  form,
}: {
  form: UseFormReturn<CompanyUpdateData>;
}) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="grid w-full grid-cols-3 gap-3">
      <FormItem className={"col-span-2"}>
        <FormLabel>{t("component.companyForm.address.lineOne")}</FormLabel>
        <Input {...register("address.lineOne")} />
        <FormMessage>{errors.address?.lineOne?.message}</FormMessage>
      </FormItem>
      <FlexOne />
      <FormItem className={"col-span-2"}>
        <Input
          {...register("address.lineTwo")}
          placeholder={t("component.companyForm.address.lineTwoPlaceholder")}
        />
        <FormMessage>{errors.address?.lineOne?.message}</FormMessage>
      </FormItem>
      <FlexOne />{" "}
      <FormItem className={"col-span-2"}>
        <Input
          {...register("address.lineThree")}
          placeholder={t("component.companyForm.address.lineThreePlaceholder")}
        />
        <FormMessage>{errors.address?.lineOne?.message}</FormMessage>
      </FormItem>
      <FlexOne />
      <FormItem>
        <FormLabel>{t("component.companyForm.address.postcodeCode")}</FormLabel>
        <Input {...register("address.postcodeCode")} />
        <FormMessage>{errors.address?.postcodeCode?.message}</FormMessage>
      </FormItem>
      {/* City Name */}
      <FormItem>
        <FormLabel>{t("component.companyForm.address.cityName")}</FormLabel>
        <Input {...register("address.cityName")} />
        <FormMessage>{errors.address?.cityName?.message}</FormMessage>
      </FormItem>
      <FlexOne />
      {/* Country Select */}
      <FormItem>
        <CountrySelect form={form} />
        <FormMessage>{errors.address?.countryCode?.message}</FormMessage>
      </FormItem>
    </div>
  );
};

export default AddressFormFields;
