import { Blob } from "@/models/blob";
import { BookingTransaction } from "@/models/BookingTransaction";
import { BankTransaction } from "@/models/bankTransaction";

export interface BiDocument {
  id: string;
  createdAt: string;
  updatedAt: string;
  contentSource: "auto" | "manual";
  kind: BiDocumentKind;
  invoiceNumber?: string;
  invoiceDate?: SimpleDate;
  performancePeriodFrom?: SimpleDate;
  performancePeriodTo?: SimpleDate;
  issuer: DocumentParty;
  recipient: DocumentParty;
  uploadDocumentId?: string;
  uploadDocument?: UploadDocument;
  pagesPreview?: PagesPreview[];

  bookingTransactions?: BookingTransaction[];
  deletedAt?: string;
  deliveryDate?: SimpleDate;
  dueDate?: SimpleDate;
  dirty?: boolean;

  invoicePositions?: DocumentPosition[];
  //
  derived: {
    positionsValid: boolean;
    totalAmount: number;
  };
}

export interface DocumentPosition {
  total: number;
  vatPercentage: string;
  category?: string;
}

export interface PagesPreview {
  id: string;
  pageNumber: number;
  blob: Blob;
  blobId: string;
  createdAt: string;
  deletedAt?: string;
  documentId: string;
  updatedAt: string;
}

export interface SimpleDate {
  year: number;
  month: number;
  day: number;
}

export function simpleDateFrom(date: Date): SimpleDate {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}

export function simpleDateToDate(date: SimpleDate): Date {
  return new Date(date.year, date.month - 1, date.day, 12);
}

export interface DocumentParty {
  name: string;
  address: string;
  taxId: string;
  iban: string;
  customerNumber?: number;
}

export interface UploadDocument {
  blob: Blob;
  ocrStatus: UploadDocumentStatus;
  ocrStartedAt: string;
  ocrFinishedAt: string;
  blobId: string;
  companyId: string;
  createdAt: string;
  creatorUserCompanyId: string;
  deletedAt?: string;
  id: string;
  ocrDataBlob?: Blob;
  ocrDataBlobId?: string;
  ocrProvider: string;
  ocrText: string;
  updatedAt: string;
}

export enum UploadDocumentStatus {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Failed = "failed",
}

export enum BiDocumentKind {
  Invoice = "invoice",
  Receipt = "receipt",
}

export interface CategorizationResponse {
  name: string;
  description: string;
  category: string;
  accountNumber: number;
  key: string;
  kind: BiDocumentKind[];
  score: number;
}

export function documentIsInvalid(item: BiDocument | BankTransaction) {
  //if item is an BankTransaction
  if ("status" in item) {
    return false;
  } else {
    let docItem = item as BiDocument;
    return (
      docItem.invoiceNumber === null ||
      docItem.invoiceDate === null ||
      (docItem.kind === BiDocumentKind.Invoice &&
        (docItem.recipient.name === "" || docItem.recipient.address === "")) ||
      (docItem.kind === BiDocumentKind.Receipt &&
        (docItem.issuer.name === "" || docItem.issuer.address === "")) ||
      !docItem.invoicePositions ||
      docItem.invoicePositions.length == 0 ||
      docItem.invoicePositions.find((i) => !i.category) ||
      docItem.invoicePositions.find((i) => !i.total)
    );
  }
}
