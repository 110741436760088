import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { t } from "i18next";
import { CompanyUpdateData } from "@/models/company";
import { LegalFormComboBox } from "@/feature/settings/UserSettings/form/optional/LegalFormComboBox";
import { cn } from "@/lib/utils";

const GeneralInfoFormField = ({
  form,
  className,
}: {
  form: UseFormReturn<CompanyUpdateData>;
  className?: string;
}) => {
  return (
    <div className={cn("grid grid-cols-3 gap-4", className)}>
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel className={"font-semibold"}>
              {t("component.companyForm.generalInfo.name")} *
              <FormMessage />
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <LegalFormComboBox form={form} />
    </div>
  );
};

export default GeneralInfoFormField;
