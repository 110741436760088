import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Link, useLocation } from "react-router-dom";

import Logo from "./Logo";
import React, { FC, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { PanelLeftClose, PanelLeftOpen } from "lucide-react";
import { t } from "i18next";
import { VerticalFlex } from "@/components/layout/Flex";
import { useScreenDimensions } from "@/components/layout/screenWidth";

export interface SidebarItem {
  title: string;
  icon: React.ReactNode;
  path: string;
  position: "top" | "middle" | "bottom";
  element: React.ReactNode;
  disabled?: boolean;
}

const SideNavbar = (props: { sidebar: SidebarItem[] }) => {
  const [expanded, setExpanded] = React.useState(true);
  const [screenWidth] = useScreenDimensions();

  useEffect(() => {
    if (screenWidth < 1600) {
      setExpanded(false);
    }
  }, [screenWidth]);

  return (
    <div
      className={
        "group/nav sticky top-0 flex h-screen flex-col bg-muted/60 p-4 shadow shadow-background backdrop-blur-md"
      }
    >
      <VerticalFlex
        justify={"center"}
        align={"center"}
        className="relative h-full"
      >
        <VerticalFlex justify={"center"} align={"center"}>
          <Tooltip>
            <TooltipTrigger>
              <Logo expanded={expanded}></Logo>
            </TooltipTrigger>
            <TooltipContent side="right">
              {t("component.app.sidebar.brand")}
            </TooltipContent>
          </Tooltip>
        </VerticalFlex>

        <Separator orientation="horizontal" />

        <nav className="flex flex-col gap-4">
          {props.sidebar.map((item, index) => (
            <React.Fragment key={index}>
              {item.position === "top" && (
                <SideBarEntry expanded={expanded} item={item} />
              )}
            </React.Fragment>
          ))}
          <Separator orientation="horizontal" />
          {props.sidebar.map((item, index) => (
            <React.Fragment key={index}>
              {item.position === "middle" && (
                <SideBarEntry expanded={expanded} item={item} />
              )}
            </React.Fragment>
          ))}
        </nav>

        <VerticalFlex className="absolute right-0 top-1/2 -translate-y-1/2 transform">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type={"button"}
                className={
                  "absolute -right-8 top-0 text-muted-foreground opacity-0 group-hover/nav:opacity-100"
                }
                variant={"outline"}
                size={"icon"}
                onClick={() => {
                  setExpanded((prev) => !prev);
                }}
              >
                {expanded ? (
                  <PanelLeftClose size={24} />
                ) : (
                  <PanelLeftOpen size={24} />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">
              {expanded
                ? t("component.app.sidebar.collapse")
                : t("component.app.sidebar.expand")}
            </TooltipContent>
          </Tooltip>
        </VerticalFlex>

        <nav className="mt-auto flex flex-col gap-4">
          {props.sidebar.map((item, index) => (
            <React.Fragment key={index}>
              {item.position === "bottom" && (
                <SideBarEntry expanded={expanded} item={item} />
              )}
            </React.Fragment>
          ))}
        </nav>
      </VerticalFlex>
    </div>
  );
};

const SideBarEntry: FC<{ item: SidebarItem; expanded: boolean }> = ({
  item,
  expanded,
}) => {
  const route = useLocation();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={!item.disabled ? item.path : route}
          className={cn(
            "flex w-full cursor-pointer items-center gap-4 p-2.5 text-muted-foreground hover:text-white",
            item.path === route.pathname &&
              "rounded-[6px] bg-white/10 text-foreground",
            item.disabled && "!cursor-not-allowed opacity-50",
          )}
          aria-disabled={item.disabled}
        >
          {item.icon}
          {expanded && <span className="text-sm">{item.title}</span>}
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{item.title}</TooltipContent>
    </Tooltip>
  );
};

export default SideNavbar;
