import { SimpleDate } from "@/models/document";
import dayjs from "dayjs";

export const formatDate = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }
  return new Date(value).toLocaleDateString("de-DE", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateNumeric = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }
  return new Date(value).toLocaleDateString("de-DE", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateAreaChart = (date: Date) => {
  return date.toLocaleDateString("de-DE", { month: "short", year: "2-digit" });
};

export const isSameDay = (a: SimpleDate, b: SimpleDate) => {
  return a.day === b.day && a.month === b.month && a.year === b.year;
};

const showYear = (date: SimpleDate, showYear: boolean) => {
  if (showYear) {
    return `${date.day}.${date.month}.${date.year}`;
  }
  return `${date.day}.${date.month}.`;
};

export const formatPeriod = (from: SimpleDate, to: SimpleDate) => {
  if (isSameDay(from, to)) {
    return showYear(from, true);
  }
  if (from.year === to.year) {
    return `${showYear(from, false)} - ${showYear(to, true)}`;
  }
  return `${showYear(from, true)} - ${showYear(to, true)}`;
};

export const formatSimpleDate = (value?: SimpleDate): string | undefined => {
  if (!value) {
    return undefined;
  }
  return new Date(
    value.year,
    value.month - 1,
    value.day,
    12,
  ).toLocaleDateString("de-DE", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatMoney = (
  value?: number,
  currency?: string,
): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currency || "EUR",
  }).format(value / 100);
};

export const formatIBAN = (iban: string) => {
  const cleanIBAN = iban.replace(/[^a-zA-Z0-9]/g, "");

  const formattedIBAN = cleanIBAN.replace(/(.{4})/g, "$1 ").trim();

  return formattedIBAN.toUpperCase();
};

export const formatUpdateAgo = (time: string) => {
  return dayjs(time).fromNow();
};

export function formatNumberSimple(value: number) {
  return Intl.NumberFormat("de-DE", {
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value / 100);
}

export function formatNumberFancy(value: number) {
  return Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value / 100);
}

export function parseNumber(value: string) {
  // Int is needed, else backend will complain
  const v = Math.round(parseFloat(value.replaceAll(",", ".")) * 100);
  if (isNaN(v)) {
    return 0;
  }
  return v;
}

export const calculateCursorPosition = (
  rawValue: string,
  formattedValue: string,
  cursorPosition: number,
) => {
  const rawValueUpToCursor = rawValue.slice(0, cursorPosition);
  const rawAlphanumeric = rawValueUpToCursor.replace(/[^a-zA-Z0-9]/g, "");
  let formattedCursorPosition = 0;
  let alphanumericCount = 0;

  for (let i = 0; i < formattedValue.length; i++) {
    if (formattedValue[i].match(/[a-zA-Z0-9]/)) {
      alphanumericCount++;
    }
    if (alphanumericCount === rawAlphanumeric.length) {
      formattedCursorPosition = i + 1;
      break;
    }
  }

  return formattedCursorPosition;
};
