// nur doc FormSimpleDate.tsx
import { FieldValues } from "react-hook-form/dist/types/fields";
import { Control, FieldPath } from "react-hook-form/dist/types";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Calendar as CalendarIcon, XIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import React from "react";
import { Calendar } from "@/components/ui/calendar";
import { useTranslation } from "react-i18next";
import { formatSimpleDate } from "@/util/format";
import { DateInput } from "@/components/ui/date-input";
import { FlexOne, HorizontalFlex } from "@/components/layout/Flex";
import { TName } from "ts-interface-checker";

export const FormSimpleDate = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: {
  label?: string;
  edit?: boolean;
  form: { control: Control<T> };
  name: TName;
  placeholder?: string;
  className?: string;
  displayClassName?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  itemClassName?: string;
  onChange?: () => void;
  optional?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        let dateValue = field.value
          ? new Date(field.value.year, field.value.month - 1, field.value.day)
          : undefined;

        if (props.edit === false) {
          if (dateValue) {
            return (
              <span className={props.displayClassName}>
                {formatSimpleDate(field.value)}
              </span>
            );
          } else {
            return (
              <span className={(props.displayClassName || "") + " opacity-50"}>
                {props.placeholder}
              </span>
            );
          }
        }

        return (
          <FormItem className={cn(props.className)}>
            {props.label && (
              <FormLabel className={"text-muted-foreground"}>
                {props.label}
              </FormLabel>
            )}
            <FormControl>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full min-w-40 justify-between text-left font-normal",
                      !field.value && "text-muted-foreground",
                    )}
                  >
                    {field.value ? (
                      formatSimpleDate(field.value)
                    ) : (
                      <span>{props.placeholder || t("ui.form.pickADate")}</span>
                    )}
                    <div className={"flex-1"} />
                    {props.optional && dateValue ? (
                      <XIcon
                        className="ml-2 h-4 w-4 text-red-600 hover:bg-red-600 hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          field.onChange(null);
                          props.onChange && props.onChange();
                        }}
                      />
                    ) : (
                      <CalendarIcon className="ml-2 h-4 w-4" />
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <HorizontalFlex className={"p-2"}>
                    <FlexOne />
                    <DateInput
                      displayNone={!dateValue}
                      value={dateValue}
                      onChange={(s) => {
                        field.onChange(
                          s
                            ? {
                                year: s.getFullYear(),
                                month: s.getMonth() + 1,
                                day: s.getDate(), // yes this is correct
                              }
                            : undefined,
                        );
                        props.onChange && props.onChange();
                      }}
                    />
                  </HorizontalFlex>
                  <Calendar
                    mode="single"
                    defaultMonth={dateValue}
                    selected={dateValue}
                    onSelect={(s) => {
                      field.onChange(
                        s
                          ? {
                              year: s.getFullYear(),
                              month: s.getMonth() + 1,
                              day: s.getDate(), // yes this is correct
                            }
                          : undefined,
                      );
                    }}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
