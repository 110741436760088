import React from "react";
import { EditCompanyForm } from "@/feature/settings/UserSettings/form/CompanyForm";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { Title } from "@/components/text/Title";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import CompanyImageUploadForm from "@/feature/settings/UserSettings/form/CompanyImageUploadForm";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";

const CompanySettingsContent = () => {
  const { activeCompany } = useLoggedIn();

  return (
    <HorizontalFlex>
      <VerticalFlex className={"max-w-screen-lg flex-1"}>
        <Title>{activeCompany.name} bearbeiten </Title>
        <HorizontalGradientLine />
        <EditCompanyForm />
      </VerticalFlex>
      <VerticalFlex className={"w-96"}>
        <CompanyImageUploadForm />
      </VerticalFlex>
    </HorizontalFlex>
  );
};

export default CompanySettingsContent;
