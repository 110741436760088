import { BiDocumentKind } from "@/models/document";
import { z } from "zod";
import { translation } from "@/i18n/translation";

const documentParty = z.object({
  name: z.string().nullable(),
  address: z.string().nullable(),
  taxId: z.string().nullable(),
  iban: z.string().nullable(),
  customerNumber: z.number().nullable().optional(),
});

const date = z.object({
  year: z.number().min(2020).max(2100),
  month: z.number().min(1).max(12),
  day: z.number().min(1).max(31),
});

const invoicePositions = z
  .object({
    category: z.string().optional(),
    vatPercentage: z.number(),
    total: z.number(),
  })
  .superRefine((data, ctx) => {
    if (!data.category) {
      ctx.addIssue({
        code: "custom",
        message: translation.t("zod:errors.empty"),
        path: ["category"],
      });
    }
    if (data.total === 0) {
      ctx.addIssue({
        code: "custom",
        message: translation.t("zod:errors.empty"),
        path: ["total"],
      });
    }
    if (data.vatPercentage < 0 || data.vatPercentage > 100) {
      ctx.addIssue({
        code: "custom",
        message: translation.t("zod:errors.custom"),
        path: ["vatPercentage"],
      });
    }
  });

export const biDocumentSchema = z
  .object({
    kind: z.enum([BiDocumentKind.Invoice, BiDocumentKind.Receipt]),
    invoiceNumber: z.string().min(3),
    invoiceDate: date,
    dueDate: date.nullable().optional(),
    performancePeriodFrom: date.nullable().optional(),
    performancePeriodTo: date.nullable().optional(),
    issuer: documentParty.optional(),
    recipient: documentParty.optional(),
    invoicePositions: z.array(invoicePositions),
    dirty: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.kind === BiDocumentKind.Invoice) {
      if (!data.recipient?.name) {
        ctx.addIssue({
          code: "custom",
          message: translation.t("zod:errors.empty"),
          path: ["recipient", "name"],
        });
      }
      if (!data.recipient?.address) {
        ctx.addIssue({
          code: "custom",
          message: translation.t("zod:errors.empty"),
          path: ["recipient", "address"],
        });
      }
    }
    if (data.kind === BiDocumentKind.Receipt) {
      if (!data.issuer?.name) {
        ctx.addIssue({
          code: "custom",
          message: translation.t("zod:errors.empty"),
          path: ["issuer", "name"],
        });
      }
      if (!data.issuer?.address) {
        ctx.addIssue({
          code: "custom",
          message: translation.t("zod:errors.empty"),
          path: ["issuer", "address"],
        });
      }
    }
  });
