import React from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { CompanyUpdateData } from "@/models/company";
import { useBankAccountsFilterQuery } from "@/api/endpoints/bankApi";
import { FilterIn } from "@/api/types";
import { BankAccount } from "@/models/bankAccount";
import { t } from "i18next";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { Input } from "@/components/ui/input";
import { PiggyBank } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";

const BankNameComboBox = ({
  form,
}: {
  form: UseFormReturn<CompanyUpdateData>;
}) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mouseEnter, setMouseEnter] = React.useState(false);
  const [account, setAccount] = React.useState<BankAccount | null>(null);

  const { data, isLoading } = useBankAccountsFilterQuery({
    ...FilterIn("bankName", search),
  });

  const value = form.watch("bankDetails.bankName");
  if (isLoading || data === undefined)
    return (
      <FormField
        control={form.control}
        name="bankDetails.bankName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t("component.companyForm.bankDetails.bankName")}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );

  const accounts = data.items as BankAccount[];
  const handleInputChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setSearch(value);
    form.setValue("bankDetails.bankName", value);
  };

  const onClickItem = (item: BankAccount) => {
    form.setValue("bankDetails.accountHolder", item.ownerName);
    form.setValue("bankDetails.bankName", item.bankName);
    form.setValue("bankDetails.iban", item.IBAN);
    if (item.IBAN.startsWith("DE")) {
      const blz = item.IBAN.substring(4, 12);
      form.setValue("bankDetails.blz", blz);
    }
    setOpen(false);
    setMouseEnter(false);
  };

  return (
    <div className={"relative"}>
      <FormField name="bankDetails.bankName" render={() => <FormMessage />} />
      <FormLabel>{t("component.companyForm.bankDetails.bankName")}</FormLabel>
      <HorizontalFlex align={"center"} className={"flex-1"} gap={2}>
        <Input
          onFocus={() => setOpen(true)}
          onBlur={() => {
            if (!mouseEnter) setOpen(false);
          }}
          onChange={(e) => handleInputChange(e)}
          value={value}
          autoComplete={"off"}
        />
        {account && (
          <PiggyBank
            size={24}
            className={"cursor-pointer"}
            onClick={() => {
              form.setValue("bankDetails.accountHolder", "");
              form.setValue("bankDetails.bankName", "");
              form.setValue("bankDetails.iban", "");
              form.setValue("bankDetails.blz", "");
              setAccount(null);
            }}
          />
        )}
      </HorizontalFlex>
      {(open || mouseEnter) && data?.items && data.items.length > 0 && (
        <ScrollArea
          className="absolute left-0 right-0 z-10 max-h-32 w-full overflow-y-auto rounded-b border bg-background/90 shadow-lg backdrop-blur-md"
          style={{ position: "absolute", top: "100%", zIndex: 1000 }}
          onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}
        >
          {accounts.map((item: BankAccount) => (
            <VerticalFlex
              key={item.id}
              onClick={() => {
                onClickItem(item);
              }}
              className="cursor-pointer bg-background/60 px-4 py-2 transition-all duration-300 hover:bg-muted-foreground/60"
            >
              <p className="max-w-96 truncate text-sm font-medium leading-none">
                {item.bankName}
              </p>
              <div className="flex flex-col text-xs text-muted-foreground">
                <p>{item.IBAN}</p>
                <p>{item.productName}</p>
              </div>
            </VerticalFlex>
          ))}
        </ScrollArea>
      )}
    </div>
  );
};

export default BankNameComboBox;
