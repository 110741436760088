// doc FormSimpleMoney.tsx
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";

import React from "react";
import { cn } from "@/lib/utils";
import {
  FormSimpleNumber,
  FormSimpleNumberProps,
} from "@/components/form/FormSimpleNumber";

export const FormSimpleMoney = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  props: FormSimpleNumberProps<T, TName>,
) => {
  return (
    <FormSimpleNumber
      postfix={(value) => (
        <span className={cn("ml-3 pt-0.5", value < 0 ? "text-red-300" : "")}>
          {"€"}
        </span>
      )}
      valueDisplay={(value) => formatNumberFancy(value)}
      parseNumber={parseNumber}
      {...props}
    />
  );
};

export function formatNumberFancy(value: number | null) {
  if (value === null || isNaN(value)) {
    return "";
  }
  return Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value / 100);
}

function parseNumber(value: string | number) {
  // Int is needed, else backend will complain
  const v = Math.round(parseFloat((value + "").replaceAll(",", ".")) * 100);
  if (isNaN(v)) {
    return 0;
  }
  return v;
}
