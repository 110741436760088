import React, { FC } from "react";
import { BiDocument, documentIsInvalid } from "@/models/document";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { DocumentResult } from "@/feature/transactions/components/DocumentResult";
import { cn } from "@/lib/utils";

export const SmallDocumentPreviewCard: FC<{
  document: BiDocument;
  highlight?: boolean;
  selected?: boolean;
  onClick?: (doc: BiDocument) => void;
}> = ({ document, highlight, onClick, selected }) => {
  return (
    <HorizontalFlex
      className={cn(
        "relative overflow-hidden rounded-[6px]",
        highlight && "order-[1px] border-dashed border-foreground/10",
      )}
      key={document.id}
    >
      <VerticalFlex className="w-full">
        <DocumentResult
          onClick={() => {
            onClick && onClick(document);
          }}
          document={document}
          selected={selected}
        ></DocumentResult>
      </VerticalFlex>
      {documentIsInvalid(document) && (
        <VerticalFlex
          justify={"center"}
          align={"center"}
          className="absolute left-0 top-0 h-full w-full opacity-0 hover:bg-background/30 hover:opacity-100 hover:backdrop-blur-sm"
        >
          <span
            className={"select-none text-sm font-semibold text-foreground/80"}
          >
            Dokument hat noch keine Kategorie
          </span>
        </VerticalFlex>
      )}
    </HorizontalFlex>
  );
};
