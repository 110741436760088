import { z } from "zod";
import { Blob } from "@/models/blob";
import { translation } from "@/i18n/translation";

// ======================== ENUMS ========================
enum VATRates {
  Zero = 0,
  Seven = 7,
  Nineteen = 19,
}
export const VATRate = z.nativeEnum(VATRates);
export const TaxSystem = z.enum(["SKR03", "SKR04"]);

export const legalForms = [
  {
    label: "Einzelunternehmen",
    description: "Ein eingetragenes Einzelunternehmen",
    value: "170",
  },
  {
    label: "Einzelunternehmen nicht eingetragen",
    description: "Ein nicht eingetragenes Einzelunternehmen",
    value: "180",
  },
  {
    label: "GmbH",
    description: "Eine Gesellschaft mit beschränkter Haftung (GmbH)",
    value: "350",
  },
  {
    label: "Unternehmergesellschaft (haftungsbeschränkt)",
    description: "Eine Unternehmergesellschaft (haftungsbeschränkt)",
    value: "351",
  },
  {
    label: "GbR",
    description: "Eine Gesellschaft des bürgerlichen Rechts",
    value: "270",
  },
  {
    label: "UG in Gründung",
    description: "Eine UG (haftungsbeschränkt) in Gründung",
    value: "356",
  },
  {
    label: "AG",
    description: "Eine Aktiengesellschaft (AG)",
    value: "310",
  },
  {
    label: "KG",
    description: "Eine Kommanditgesellschaft (KG)",
    value: "220",
  },
  {
    label: "OHG",
    description: "Eine Offene Handelsgesellschaft (OHG)",
    value: "210",
  },
  {
    label: "e.V.",
    description: "Ein eingetragener Verein",
    value: "590",
  },
  {
    label: "GmbH & Co. KG",
    description: "Eine Gesellschaft mit beschränkter Haftung & Co. KG",
    value: "230",
  },
  {
    label: "GmbH & Co. OHG",
    description: "Eine Gesellschaft mit beschränkter Haftung & Co. OHG",
    value: "240",
  },
  {
    label: "UG & Co. KG",
    description: "Eine Unternehmergesellschaft (haftungsbeschränkt) & Co.KG",
    value: "232",
  },
  {
    label: "AG & Co. KG",
    description: "Eine Aktiengesellschaft & Co. Kommanditgesellschaft",
    value: "250",
  },
  {
    label: "SE",
    description: "Eine Europäische Aktiengesellschaft (SE)",
    value: "360",
  },
  {
    label: "eG",
    description: "Eine eingetragene Genossenschaft (eG)",
    value: "400",
  },
  {
    label: "KGaA",
    description: "Eine Kommanditgesellschaft auf Aktien",
    value: "320",
  },
  {
    label: "AG & Co. KGaA",
    description:
      "Eine Aktiengesellschaft & Co. Kommanditgesellschaft auf Aktien",
    value: "321",
  },
  {
    label: "GmbH & Co. KGaA",
    description: "Eine Gesellschaft mit beschränkter Haftung & Co. KGaA",
    value: "322",
  },
  {
    label: "UG & Co. KGaA",
    description: "Eine Unternehmergesellschaft (haftungsbeschränkt) & Co. KGaA",
    value: "323",
  },
  {
    label: "SE & Co. KG",
    description: "Eine Europäische Aktiengesellschaft & Co. KG",
    value: "251",
  },
  {
    label: "SE & Co. KGaA",
    description:
      "Eine Europäische Aktiengesellschaft & Co. Kommanditgesellschaft auf Aktien",
    value: "324",
  },
  {
    label: "KGaA & Co.",
    description: "Kommanditgesellschaft auf Aktien & Co. KG",
    value: "223",
  },
  {
    label: "KGaA & CoOHG",
    description:
      "Eine Kommanditgesellschaft auf Aktien & Co. Offene Handelsgesellschaft",
    value: "211",
  },
  {
    label: "Ltd & Co. KG",
    description: "Eine Private Company Limited by Shares & Co. KG",
    value: "231",
  },
  {
    label: "Ltd & Co. OHG",
    description: "Eine Private Company Limited by Shares & Co. OHG",
    value: "241",
  },
  {
    label: "UG & Co. OHG",
    description: "Eine Unternehmergesellschaft (haftungsbeschränkt) & Co. OHG",
    value: "242",
  },
  {
    label: "AG & Co. OHG",
    description: "Eine Aktiengesellschaft & Co. Offene Handelsgesellschaft",
    value: "260",
  },
  {
    label: "EWIV",
    description: "Eine Europäische Wirtschaftliche Interessenvereinigung",
    value: "280",
  },
  {
    label: "GmbH in Gründung",
    description: "Eine GmbH in Gründung",
    value: "355",
  },
  {
    label: "SCE",
    description: "Eine Europäische Genossenschaft (SCE)",
    value: "450",
  },
  {
    label: "VVaG",
    description: "Ein Versicherungsverein auf Gegenseitigkeit (VVaG)",
    value: "510",
  },
  {
    label: "Nicht rechtsfähiger Verein",
    description: "Ein nicht rechtsfähiger Verein",
    value: "610",
  },
  {
    label: "Rechtsfähige Stiftung",
    description: "Eine rechtsfähige Stiftung",
    value: "611",
  },
  {
    label: "Sonst. Personengesellschaft",
    description:
      "Eine sonstige nicht aufgeführte deutsche Personengesellschaft",
    value: "290",
  },
  {
    label: "Stiftung & Co. KG",
    description: "Eine Stiftung & Co. Kommanditgesellschaft",
    value: "221",
  },
  {
    label: "Genossenschaft & Co. KG",
    description: "Eine eingetragene Genossenschaft & Co. KG",
    value: "222",
  },
  {
    label: "Sonst. jur. Person",
    description:
      "Eine sonstige nicht aufgeführte deutsche Rechtsform - juristische Person",
    value: "700",
  },
  {
    label: "Sonst. Betriebe öffentlicher Körperschaften",
    description:
      "Sonstige Betriebe gewerblicher Art von Körperschaften des öffentlichen Rechts",
    value: "790",
  },
];

const legalFormValues = [...legalForms.map((form) => form.value)] as const;

const legalFormValuesTuple = legalFormValues as [string, ...string[]];

const LegalFormEnum = z.enum(legalFormValuesTuple);

// ======================== SCHEMAS ========================

export const CompanyAddress = z.object({
  postcodeCode: z.string().optional(),
  lineOne: z.string().optional(),
  lineTwo: z.string().optional(),
  lineThree: z.string().optional(),
  cityName: z.string().optional(),
  countryCode: z.string().optional(),
  countrySubDivisionName: z.string().optional(),
});

export const BankDetails = z.object({
  bankName: z.string().optional(),
  accountNumber: z.string().optional(),
  blz: z.string().optional(),
  iban: z
    .string()
    .regex(
      /^$|^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/gm,
      { message: translation.t("zod:errors.invalidIban") },
    )
    .optional(),

  bic: z.string().optional(),
  accountHolder: z.string().optional(),
});
const vatPatterns: { [key: string]: RegExp } = {
  DE: /^DE[0-9]{9}$/, // Deutschland
  AT: /^ATU[0-9]{8}$/, // Österreich
  BE: /^BE0[0-9]{9}$/, // Belgien
  DK: /^DK[0-9]{8}$/, // Dänemark
  FR: /^FR[A-Z0-9]{2}[0-9]{9}$/, // Frankreich (2 alphanumerische Zeichen + 9 Ziffern)
  IT: /^IT[0-9]{11}$/, // Italien
  NL: /^NL[0-9]{9}B[0-9]{2}$/, // Niederlande
  ES: /^ES[A-Z0-9]{1}[0-9]{7}[A-Z0-9]{1}$/, // Spanien
  PL: /^PL[0-9]{10}$/, // Polen
  SE: /^SE[0-9]{12}$/, // Schweden
  FI: /^FI[0-9]{8}$/, // Finnland
};

export const Accounting = z.object({
  taxId: z
    .string()
    .refine((value) => value === "" || value.length === 13, {
      message: translation.t("zod:errors.taxIdLength"),
    })
    .optional(),

  vatId: z
    .string()
    .refine(
      (id) => {
        const countryCode = id.slice(0, 2);

        return (vatPatterns[countryCode]?.test(id) ?? false) || id === "";
      },
      {
        message: translation.t("zod:errors.vatIdLength"),
      },
    )
    .optional(),

  registrationCourt: z.string().optional(),
  commercialRegister: z.string().optional(),
  vatRate: VATRate.optional(),
  taxSystem: TaxSystem.optional(),
  isSmallBusiness: z.boolean(),
  vatLiability: z.boolean().optional(),
});

export const CompanyContactInfo = z.object({
  contactPersonName: z.string(),
  email: z.string().optional(),
  phone: z.string().optional(),
  website: z.string().optional(),
});

export type Company = z.infer<typeof company> & {
  logoBlob: Blob;
  logoBlobId: string;
};

export const company = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  name: z.string(),
  companySuffix: z.string().optional(),
  legalForm: LegalFormEnum.optional(),
  contactInfo: CompanyContactInfo.optional(),
  accounting: Accounting.optional(),
  bankDetails: BankDetails.optional(),
  address: CompanyAddress.optional(),
});

export type CompanyUpdateData = z.infer<typeof CompanyUpdateSchema>;

export const CompanyUpdateSchema = z.object({
  name: z.string(),
  companySuffix: z.string().optional(),
  legalForm: LegalFormEnum,
  contactInfo: CompanyContactInfo.optional(),
  accounting: Accounting.optional(),
  bankDetails: BankDetails.optional(),
  address: CompanyAddress.optional(),
});
