import { Separator } from "@/components/ui/separator";
import { useLoggedIn } from "@/feature/auth/ProtectedRoutes";
import { ProfileForm } from "./components/ProfileForm";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { AddCompanyButton } from "@/components/company/AddCompanyButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { UserCompanyStatus, UserRole } from "@/models/userCompany";
import { formatDate } from "@/util/format";

const UserSettingsContent = () => {
  const { user, identities } = useLoggedIn();
  return (
    <VerticalFlex className={"gap-16 py-8"}>
      <div className={"grid grid-cols-[15%_1fr] gap-8 rounded-xl bg-card p-8"}>
        <span className={"font-semibold"}>Profil</span>
        <HorizontalFlex className={"max-w-[1080px]"}>
          <ProfileForm user={user} />
        </HorizontalFlex>
      </div>

      <div className={"rounded-xl bg-card p-8"}>
        <HorizontalFlex>
          <FlexOne />
          <AddCompanyButton />
        </HorizontalFlex>

        <div className={"grid grid-cols-[15%_1fr] gap-8"}>
          <VerticalFlex>
            <span className={"font-semibold"}>Unternehmen</span>
          </VerticalFlex>
          <Table className={"p-0"}>
            <TableHeader>
              <TableRow>
                <TableHead className={"w-24"}>Status</TableHead>
                <TableHead className={"w-24"}>Rolle</TableHead>
                <TableHead>Name</TableHead>
                <TableHead className="w-28 text-right">Erstellt am</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {identities
                .filter((uc) => uc.status === UserCompanyStatus.ACTIVE)
                .map((uc) => (
                  <TableRow key={uc.company.id}>
                    <TableCell className={"w-24"}>
                      {uc.status?.trim() === UserCompanyStatus.ACTIVE
                        ? "Aktiv"
                        : uc.status?.trim() === UserCompanyStatus.INVITED
                          ? "Eingeladen"
                          : "-"}
                    </TableCell>
                    <TableCell className={"w-24"}>
                      {uc.role?.trim() === UserRole.OWNER
                        ? "Inhaber"
                        : uc.role?.trim() === UserRole.NORMAL
                          ? "Nutzer"
                          : "-"}
                    </TableCell>
                    <TableCell>{uc.company?.name?.trim() || "-"}</TableCell>
                    <TableCell className="w-28 text-right">
                      {formatDate(uc.company.createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </VerticalFlex>
  );
};

export default UserSettingsContent;
